<template>
  <ion-page>
    <!-- HEADER -->
    <ion-header>
      <ion-toolbar color="dark">
        <!-- TITLE -->
        <ion-item class="page-title ion-no-padding" lines="none" color="dark">
          <ion-icon slot="start" size="small" :icon="download" color="dark"></ion-icon>
          <ion-label>
            <ion-text color="primary">
              <h2><b>Materiales</b></h2>
            </ion-text>
          </ion-label>
        </ion-item>
        <!-- BUTTONS -->
       <ion-buttons slot="start">
          <ion-menu-toggle>
            <ion-menu-button color="primary"></ion-menu-button>
          </ion-menu-toggle>
        </ion-buttons>
        <ion-button slot="end" fill="clear" class="ion-no-padding" @click="toggleSearchbar()">
          <ion-icon :icon="searchOutline"></ion-icon>
        </ion-button>
        <!--ion-button slot="end" fill="clear" class="ion-no-padding">
          <ion-icon :icon="options"></ion-icon>
        </ion-button-->
      </ion-toolbar>
      <ion-toolbar
      color="dark"
      :class="(showSearchbar) ? 'searchbar' : 'hide-searchbar'">
        <ion-searchbar 
          placeholder="Buscar..."
          mode="md" showCancelButton="always"
          showClearButton="never"
          @ionInput="searchItems($event.target.value)"
          @ionCancel="toggleSearchbar()">
        </ion-searchbar>
      </ion-toolbar>
    </ion-header>
    
    <!-- CONTENT -->
    <ion-content>
      <section>
        <div class="container">
          <ion-grid class="ion-no-padding">
            <ion-row v-if="!isLoading">
              <ion-col size-lg="3" size="4" v-for="movie in movies.data" :key="movie.id">
                <MovieItem class="ion-margin-bottom" :movie="movie" :showTitle="true" :showDateTag="true"/>
              </ion-col>
            </ion-row>
            <ion-row v-else>
              <ion-col size-lg="3" size="4" v-for="(i,index) in 12" :key="index">
                <MovieItem class="ion-margin-bottom" :showTitle="true" :showDateTag="true"/>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center">
              <ion-col size="6" size-lg="2">
                <ion-button size="block" color="dark" v-if="loadMoreButton" @click="loadMore()">
                  <ion-text color="primary" v-if="!isLoadingMore">Cargar Más</ion-text>
                  <ion-spinner v-else name="crescent" color="primary"></ion-spinner>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>

          <div class="not-found" v-show="movies.data && movies.data.length === 0">
            <div class="not-found-content"></div>
            <img src="/assets/search-magnifier.svg" width="64" alt="">
            <h2>No se encontraron resutados</h2>
          </div>
        </div>
      </section>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonGrid,
  IonCol,
  IonSearchbar,
  IonIcon,
  IonText,
  IonLabel,
  IonItem,
  IonMenuButton,
  IonMenuToggle,
  IonButtons,
  IonButton,
  IonRow,
  IonSpinner
} from '@ionic/vue';
import { download, searchOutline, options, chevronForwardOutline } from 'ionicons/icons';
import MovieItem from '../../components/MovieItem.vue'
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Materiales',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonGrid,
    IonCol,
    MovieItem,
    IonSearchbar,
    IonIcon,
    IonText,
    IonLabel,
    IonItem,
    IonMenuButton,
    IonMenuToggle,
    IonButtons,
    IonButton,
    IonRow,
    IonSpinner
  },
  setup(){
    return{
      download,
      options,
      searchOutline
    }
  },
  data(){
    return{
      server: 'https://admin.enelcine.com.ar/api',
      page: 1,
      isLoading: false,
      movies: Promise as any,
      showSearchbar: false,
      searchTimeout: setTimeout,
      isLoadingMore: false,
      loadMoreButton: true
    }
  },
  mounted(){
      this.loadPage()
  },
  methods:{
    loadPage: async function(){
        this.isLoading = true
        this.movies = await this.getItems('peliculas', 12, 'filter[estado][eq]=publicado&sort=-fecha_local&access_token='+this.$store.state.token)
        this.isLoading = false
    },
    getItems: async function(category: string, limit: number, filter: string) {

      let endpoint: string

      endpoint = '/items/'+category+'?page='+this.page+'&fields=*,portada.*,poster.*,genero.generos_id.nombre'

      if(filter !== null){
        endpoint+= '&'+filter
      }

      if( limit > 0 ){
        endpoint+= '&limit='+limit
      }

      const response = await this.axios.get(this.server + endpoint)/*.catch( error => {
        console.log(error.response)
        //router.push('/disconnected')
        throw 'Error de Servidor'
      })*/

      const items: any = response.data

      items.data.forEach((movie: any,index: number) => {
          items.data[index].titulo = (movie.titulo_local) ? movie.titulo_local : movie.titulo_original

          if(movie.fecha_local !== null){
            items.data[index].fecha_local = this.getFormatedDate(movie.fecha_local)
          }else{
            items.data[index].fecha_local = 'A Confirmar'
          }

          /*if(movie.poster === null){
            items.data[index].poster = this.config.imagen_no_disponible
          }*/
      })

      return items
    },
    getFormatedDate: function (date: any){
      date = new Date(date+'T12:00:00.000Z')
      date = new Intl.DateTimeFormat('es-ES', {dateStyle: 'medium', timeZone: 'America/Argentina/Buenos_Aires'}).format(date)
      return date
    },
    toggleSearchbar: function(){
      this.showSearchbar = !this.showSearchbar
      if(this.showSearchbar == false){
        this.loadPage()
        this.loadMoreButton= true
      }else{
        this.loadMoreButton= false
      }
    },
    searchItems: async function(query: string){
      clearTimeout(this.searchTimeout as any)

      if(query.length > 1 && query !== ""){
        this.searchTimeout = setTimeout( async () => {
          this.movies = await this.getItems('peliculas', 0, 'filter[titulo_local][like]='+query+'&filter[titulo_original][logical]=or&filter[titulo_original][like]='+query)
        }, 500) as any
      }
    },
    loadMore: async function(){
        //get previous movies
        const previousMovies= this.movies.data
        this.isLoadingMore = true
        this.page++
        this.movies = await this.getItems('peliculas', 12, 'filter[estado][eq]=publicado&sort=-fecha_local&access_token='+this.$store.state.token)
        this.movies.data = previousMovies.concat(this.movies.data)
        this.isLoadingMore = false
    }
  },
  /*watch:{
      $route (to, from){
          if( to.params.slug != this.page && !to.params.slug && this.$route.path == '/login'){
            this.loadPage()
          }
      }
  }*/
});
</script>

<style scoped>
.section{
  height: 100%;
}

.searchbar{
  top: 0px;
  left: 0px;
  position: absolute;
  z-index: 100;
}

.hide-searchbar{
  display: none;
}

.not-found{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-width: 768px){
  .section-title{
      font-size: 18px;
  }

  ion-col{
    margin-bottom: 16px;
    padding: 4px;
  }
}
</style>