
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonGrid,
  IonCol,
  IonSearchbar,
  IonIcon,
  IonText,
  IonLabel,
  IonItem,
  IonMenuButton,
  IonMenuToggle,
  IonButtons,
  IonButton,
  IonRow,
  IonSpinner
} from '@ionic/vue';
import { download, searchOutline, options, chevronForwardOutline } from 'ionicons/icons';
import MovieItem from '../../components/MovieItem.vue'
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Materiales',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonGrid,
    IonCol,
    MovieItem,
    IonSearchbar,
    IonIcon,
    IonText,
    IonLabel,
    IonItem,
    IonMenuButton,
    IonMenuToggle,
    IonButtons,
    IonButton,
    IonRow,
    IonSpinner
  },
  setup(){
    return{
      download,
      options,
      searchOutline
    }
  },
  data(){
    return{
      server: 'https://admin.enelcine.com.ar/api',
      page: 1,
      isLoading: false,
      movies: Promise as any,
      showSearchbar: false,
      searchTimeout: setTimeout,
      isLoadingMore: false,
      loadMoreButton: true
    }
  },
  mounted(){
      this.loadPage()
  },
  methods:{
    loadPage: async function(){
        this.isLoading = true
        this.movies = await this.getItems('peliculas', 12, 'filter[estado][eq]=publicado&sort=-fecha_local&access_token='+this.$store.state.token)
        this.isLoading = false
    },
    getItems: async function(category: string, limit: number, filter: string) {

      let endpoint: string

      endpoint = '/items/'+category+'?page='+this.page+'&fields=*,portada.*,poster.*,genero.generos_id.nombre'

      if(filter !== null){
        endpoint+= '&'+filter
      }

      if( limit > 0 ){
        endpoint+= '&limit='+limit
      }

      const response = await this.axios.get(this.server + endpoint)/*.catch( error => {
        console.log(error.response)
        //router.push('/disconnected')
        throw 'Error de Servidor'
      })*/

      const items: any = response.data

      items.data.forEach((movie: any,index: number) => {
          items.data[index].titulo = (movie.titulo_local) ? movie.titulo_local : movie.titulo_original

          if(movie.fecha_local !== null){
            items.data[index].fecha_local = this.getFormatedDate(movie.fecha_local)
          }else{
            items.data[index].fecha_local = 'A Confirmar'
          }

          /*if(movie.poster === null){
            items.data[index].poster = this.config.imagen_no_disponible
          }*/
      })

      return items
    },
    getFormatedDate: function (date: any){
      date = new Date(date+'T12:00:00.000Z')
      date = new Intl.DateTimeFormat('es-ES', {dateStyle: 'medium', timeZone: 'America/Argentina/Buenos_Aires'}).format(date)
      return date
    },
    toggleSearchbar: function(){
      this.showSearchbar = !this.showSearchbar
      if(this.showSearchbar == false){
        this.loadPage()
        this.loadMoreButton= true
      }else{
        this.loadMoreButton= false
      }
    },
    searchItems: async function(query: string){
      clearTimeout(this.searchTimeout as any)

      if(query.length > 1 && query !== ""){
        this.searchTimeout = setTimeout( async () => {
          this.movies = await this.getItems('peliculas', 0, 'filter[titulo_local][like]='+query+'&filter[titulo_original][logical]=or&filter[titulo_original][like]='+query)
        }, 500) as any
      }
    },
    loadMore: async function(){
        //get previous movies
        const previousMovies= this.movies.data
        this.isLoadingMore = true
        this.page++
        this.movies = await this.getItems('peliculas', 12, 'filter[estado][eq]=publicado&sort=-fecha_local&access_token='+this.$store.state.token)
        this.movies.data = previousMovies.concat(this.movies.data)
        this.isLoadingMore = false
    }
  },
  /*watch:{
      $route (to, from){
          if( to.params.slug != this.page && !to.params.slug && this.$route.path == '/login'){
            this.loadPage()
          }
      }
  }*/
});
